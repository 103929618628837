<template>
  <div>
    <v-dialog v-model="isShow" persistent activator="parent" width="351">
      <v-card class="smb-popup">
        <v-card-title>
          Psst! <br />
          Want to make your content truly
          <span :style="{ color: '#70C5B0' }">one-of-a-kind</span>?
          {{ title }}
        </v-card-title>
        <v-card-actions class="smb-popup__actions">
          <v-btn
            class="smb-popup__button"
            color="#1D1D1B"
            @click="redirectToBrandingSettings"
            >{{ btnText }}</v-btn
          >
          <div class="smb-popup__skip-btn">
            <v-btn class="smb-popup__button-border" @click="$emit(emitName)"
              >Skip</v-btn
            >
            <span class="text-description">I’ll do it later</span>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_BRANDING_TYPES } from "@/constants/smb-client";

export default {
  name: "SmbPopup",
  data: () => ({
    isChecked: false,
  }),
  computed: {
    ...mapGetters("smbClientSelection", ["isCanSeeDemoFeatures"]),
    title() {
      return this.actionType ===
        UPDATE_BRANDING_TYPES.NEED_TO_UPDATE_LOGO_AND_COLORS
        ? "Input your branding details first, and you'll see the magic happen"
        : this.actionType === UPDATE_BRANDING_TYPES.NEED_TO_UPDATE_LOGO
        ? "Input your logo first, and you'll see the magic happen"
        : "Input your brand colors first, and you'll see the magic happen";
    },
    btnText() {
      return this.actionType ===
        UPDATE_BRANDING_TYPES.NEED_TO_UPDATE_LOGO_AND_COLORS
        ? "Setup account"
        : this.actionType === UPDATE_BRANDING_TYPES.NEED_TO_UPDATE_LOGO
        ? "Upload logo"
        : "Choose colors";
    },
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    actionType: {
      type: Number,
      default: null,
    },
    emitName: {
      type: String,
      default: "skip",
    },
    buttonsData: {
      type: Object,
      default: () => {},
    },
    nameOfPageWhereWillRedirect: {
      type: String,
      default: "",
    },
  },
  methods: {
    redirectToBrandingSettings() {
      this.$router.push({
        name: "branding-settings",
        params: {
          actionType: this.actionType,
          nameOfRedirectedPage:
            this.nameOfPageWhereWillRedirect || this.$route.name,
          buttonsData: this.buttonsData,
          emitName: this.emitName,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.smb-popup {
  &__button {
    color: #000000;
    background: #92c4ff !important;
    border-radius: 16px;
    text-transform: none;
    letter-spacing: 0;
    padding: 0 15px !important;
    box-shadow: none;
    margin-right: 30px;
  }

  &__button-border {
    background: #ffffff !important;
    border-radius: 16px;
    text-transform: none;
    letter-spacing: 0;
    padding: 0 15px !important;
    box-shadow: none;
    border: 1px solid #000000;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;

    .v-input--selection-controls {
      label {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  &__skip-btn {
    display: inline-flex;
    flex-direction: column;
    .text-description {
      font-weight: 400;
      font-size: 10px;
      color: #4e5778;
      opacity: 0.6;
      text-align: center;
      margin-top: 5px !important;
    }
  }

  .v-card__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    padding-top: 36px;
    padding-bottom: 15px;
    word-break: break-word;
    display: inline-block;
  }
}
</style>

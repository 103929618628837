<template>
  <v-btn
    :class="['btn-text', customClass]"
    height="37"
    width="100%"
    color="#92C4FF"
    :loading="isLoading"
    @click="$emit(emitName, { emitName, text })"
  >
    {{ text }} <span v-if="subText.length" class="sub-text">{{ subText }}</span>
    <v-icon v-if="appendIcon" right> {{ appendIcon }} </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "SmbMenuButton",
  props: {
    text: {
      type: String,
      default: "",
    },
    subText: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
    emitName: {
      type: String,
      default: "click",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.btn-text {
  color: #1d1d1b;
  font-weight: 600 !important;
  font-size: 18px;
  border: 1px solid #1d1d1b !important;
  border-radius: 5px;
  text-align: right;
  justify-content: flex-start;
  text-transform: none;
  letter-spacing: 0;
  padding: 0 13px !important;
  flex: 1;
  box-shadow: none !important;
  @media screen and (max-width: 410px) {
    font-size: 15px;
  }
  &.small-text {
    font-size: 11px;
    max-width: 101px;
  }
  .sub-text {
    font-size: 11px;
    font-weight: 500;
    margin-left: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }
  ::v-deep {
    .v-btn__content {
      align-items: baseline;
      flex: auto;
      width: 100%;
    }
    .mdi-chevron-right {
      margin-left: auto;
    }
  }
}
</style>
